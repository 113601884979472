import React from "react";
import { useTranslation } from "react-i18next";

// TASK IMPORTANT DOCUMENTS READY
export const GeneralInformationDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");

  return (
    <div>
      <p>
        {t("tasks.commuting.general-information-paragraph1")}{" "}
        <a href="/contact-us" target="_blank" rel="noopener noreferrer">
          <strong>{t("tasks.commuting.general-information-paragraph1-link")}</strong>
        </a>
      </p>
    </div>
  );
};

// TASK CERTIFICATE RESIDENCE
export const CertificateResidenceDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>
        {t("tasks.commuting.certificate-residence-paragraph1")}{" "}
        <a
          href="https://www.formulare-bfinv.de/ffw/form/display.do?%24context=5927A66C179B6BDB71B3"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("tasks.commuting.certificate-residence-paragraph1-link1")}
        </a>{" "}
        {t("tasks.commuting.certificate-residence-paragraph1-part2")}{" "}
        <a href="/videolibrary" target="_blank" rel="noopener noreferrer">
          {t("tasks.commuting.certificate-residence-paragraph1-link2")}
        </a>
      </p>
    </div>
  );
};

// TASK WORK PERMIT
export const WorkPermitDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>
        {t("tasks.commuting.work-permit-paragraph1")}{" "}
        <a href="/videolibrary" target="_blank" rel="noopener noreferrer">
          <strong>{t("tasks.commuting.work-permit-paragraph1-link")}</strong>
        </a>
      </p>
    </div>
  );
};

// TASK BANK ACCOUNT

export const OpenBankAccountCommuterDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.commuting.open-bank-paragraph1")}</p>
    </div>
  );
};

// TASK PHONE ACCOUNT

export const MobilePhoneCommuterDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>
        {t("tasks.commuting.mobile-phone-paragraph1")}{" "}
        <a href="/videolibrary" target="_blank" rel="noopener noreferrer">
          <strong>{t("tasks.commuting.mobile-phone-paragraph1-link")}</strong>
        </a>
      </p>
    </div>
  );
};

// TASK HEALTH INSURANCE

export const HealthInsuranceCommuterDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>
        {t("tasks.commuting.health-insurance-paragraph1")}{" "}
        <a href="/contact-us" target="_blank" rel="noopener noreferrer">
          {t("tasks.commuting.health-insurance-paragraph1-link")}
        </a>{" "}
        {t("tasks.commuting.health-insurance-paragraph1-part2")}{" "}
        <a href="/videolibrary" target="_blank" rel="noopener noreferrer">
          {t("tasks.commuting.health-insurance-paragraph1-part2-link")}
        </a>
      </p>

      <ul>
        <li>
          <strong>
            {t("tasks.commuting.health-insurance-list1-item1-bold")}{" "}
          </strong>
          {t("tasks.commuting.health-insurance-list1-item1")}
        </li>
        <li>
          <strong>
            {t("tasks.commuting.health-insurance-list1-item2-bold")}{" "}
          </strong>
          {t("tasks.commuting.health-insurance-list1-item2")}
        </li>
        <li>
          <strong>
            {t("tasks.commuting.health-insurance-list1-item3-bold")}{" "}
          </strong>
          {t("tasks.commuting.health-insurance-list1-item3")}
        </li>
      </ul>
    </div>
  );
};

// TASK INSURANCES FOR COMMUTERS

export const InsurancesCommuterDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.commuting.insurance-commuters-paragraph1")}</p>
      <ul>
        <li>
          <strong>
            {t("tasks.commuting.insurance-commuters-list1-item1-bold")}{" "}
          </strong>
          {t("tasks.commuting.insurance-commuters-list1-item1")}
        </li>
        <li>
          <strong>
            {t("tasks.commuting.insurance-commuters-list1-item2-bold")}{" "}
          </strong>
          {t("tasks.commuting.insurance-commuters-list1-item2")}
        </li>
        <li>
          <strong>
            {t("tasks.commuting.insurance-commuters-list1-item3-bold")}{" "}
          </strong>
          {t("tasks.commuting.insurance-commuters-list1-item3")}
        </li>
        <li>
          <strong>
            {t("tasks.commuting.insurance-commuters-list1-item4-bold")}{" "}
          </strong>
          {t("tasks.commuting.insurance-commuters-list1-item4")}
        </li>
        <li>
          <strong>
            {t("tasks.commuting.insurance-commuters-list1-item5-bold")}{" "}
          </strong>
          {t("tasks.commuting.insurance-commuters-list1-item5")}
        </li>
      </ul>
    </div>
  );
};

// TASK RETIREMENT FOR COMMUTERS

export const RetirementCommuterDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.commuting.retirement-paragraph1")}</p>
      <ul>
        <li>
          <strong>{t("tasks.commuting.retirement-list1-item1-bold")}</strong>{" "}
          {t("tasks.commuting.retirement-list1-item1")}
        </li>
        <li>
          <strong>{t("tasks.commuting.retirement-list1-item2-bold")}</strong>{" "}
          {t("tasks.commuting.retirement-list1-item2")}
        </li>
        <li>
          <strong>{t("tasks.commuting.retirement-list1-item3-bold")}</strong>{" "}
          {t("tasks.commuting.retirement-list1-item3")}
        </li>
      </ul>
    </div>
  );
};

// TASK TAXATION

export const TaxationCommuterDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
  
      <p>
        {t("tasks.commuting.taxation-paragraph1-part1")}{" "}
        <a
          href="https://www.grenzgaengerdienst.de/brutto-netto-rechner-grenzgaenger-schweiz"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("tasks.commuting.taxation-paragraph1-link")}
        </a>{" "}
        {t("tasks.commuting.taxation-paragraph1-part2")}{" "}
      </p>
    </div>
  );
};

// TASK CHILD ALLOWANCE ACCOUNT

export const ChildAllowanceCommuterDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.commuting.child-allowance-paragraph1")}</p>
      <p>{t("tasks.commuting.child-allowance-paragraph2")}</p>
    </div>
  );
};


// TASK PROPERTY PURCHASE ACCOUNT

export const PropertyPurchaseCommuterDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.commuting.property-purchase-paragraph1")}</p>
    </div>
  );
};
