import React from "react";
import { useTranslation } from "react-i18next";

// TASK IMPORTANT REGISTER SWISS MUNICIPALITY
export const RegisterSwissDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");

  return (
    <div>
      <p>{t("tasks.group3.register-swiss-paragraph1")}</p>

      <ul>
        <li>
          <a href="/videolibrary" target="_blank" rel="noopener noreferrer">
            <strong>{t("tasks.group3.register-swiss-list1-item1-bold")}</strong>
          </a>{" "}
          {t("tasks.group3.register-swiss-list1-item1-normal")}
        </li>
        <li>
          <strong>{t("tasks.group3.register-swiss-list1-item2-bold")}</strong>{" "}
          {t("tasks.group3.register-swiss-list1-item2-normal")}
        </li>
        <li>
          <strong>{t("tasks.group3.register-swiss-list1-item3-bold")}</strong>{" "}
          {t("tasks.group3.register-swiss-list1-item3-normal")}
        </li>
      </ul>
    </div>
  );
};

// TASK RESIDENCE PERMIT
export const ResidencePermitDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>
        {t("tasks.group3.residence-permit-paragraph1")}
        <a href="/videolibrary" target="_blank" rel="noopener noreferrer">
          <strong>{t("tasks.group3.residence-permit-list2-bold")} </strong>
        </a>{" "}
        {t("tasks.group3.residence-permit-list2-normal")}
      </p>

      <ul>
        <li>
          <strong>{t("tasks.group3.residence-permit-sublist2-bold")}</strong>{" "}
          {t("tasks.group3.residence-permit-sublist2-normal")}
        </li>
        <li>
          <strong>{t("tasks.group3.residence-permit-sublist3-bold")}</strong>{" "}
          {t("tasks.group3.residence-permit-sublist3-normal")}
        </li>
        <li>
          <strong>{t("tasks.group3.residence-permit-sublist4-bold")}</strong>{" "}
          {t("tasks.group3.residence-permit-sublist4-normal")}
        </li>
        <li>
          <strong>{t("tasks.group3.residence-permit-sublist5-bold")}</strong>{" "}
          {t("tasks.group3.residence-permit-sublist5-normal")}
        </li>
        <li>
          <strong>{t("tasks.group3.residence-permit-sublist6-bold")}</strong>{" "}
          {t("tasks.group3.residence-permit-sublist6-normal")}
        </li>
      </ul>
    </div>
  );
};

// TASK BANK ACCOUNT

export const OpenBankAccountDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>
        {t("tasks.group3.open-bank-paragraph1")}{" "}
        <a href="/contact-us" target="_blank" rel="noopener noreferrer">
          {t("tasks.group3.open-bank-paragraph1-link")}
        </a>{" "}
        {t("tasks.group3.open-bank-paragraph2")}
      </p>
    </div>
  );
};

// TASK CURRENCY EXCHANGE

export const CurrencyExchangeDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.group3.currency-exchange-paragraph1")}</p>

      <ul>
        <li>
          <strong>
            {t("tasks.group3.currency-exchange-list1-item1-bold")}
          </strong>{" "}
          {t("tasks.group3.currency-exchange-list1-item1-normal")}
        </li>
        <li>
          <strong>
            {t("tasks.group3.currency-exchange-list1-item2-bold")}
          </strong>{" "}
          {t("tasks.group3.currency-exchange-list1-item2-normal")}
        </li>
      </ul>
    </div>
  );
};

// TASK PHONE

export const PhoneAndInternetDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.group3.phone-internet-paragraph1")}</p>

      <ul>
        <li>
          <strong>{t("tasks.group3.phone-internet-list1-item1-bold")}</strong>{" "}
          {t("tasks.group3.phone-internet-list1-item1-normal")}
        </li>
        <li>
          <strong>{t("tasks.group3.phone-internet-list1-item2-bold")}</strong>{" "}
          {t("tasks.group3.phone-internet-list1-item2-normal")}
        </li>
      </ul>
    </div>
  );
};

// TASK REGISTER PETS

export const RegisterPetsDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.group3.register-pets-paragraph1")}</p>
      <ul>
        <li>
          <a href="/videolibrary" target="_blank" rel="noopener noreferrer">
            <strong>{t("tasks.group3.register-pets-list1-item1-bold")}</strong>
          </a>{" "}
          {t("tasks.group3.register-pets-list1-item1")}
        </li>
        <li>
          <strong>{t("tasks.group3.register-pets-list1-item2-bold")}</strong>{" "}
          {t("tasks.group3.register-pets-list1-item2")}
        </li>
        <li>
          <strong>{t("tasks.group3.register-pets-list1-item3-bold")}</strong>{" "}
          {t("tasks.group3.register-pets-list1-item3")}
        </li>
        <li>
          <strong>{t("tasks.group3.register-pets-list1-item4-bold")}</strong>{" "}
          {t("tasks.group3.register-pets-list1-item4")}
        </li>
      </ul>
    </div>
  );
};

export const HealthInsuranceDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.group3.health-insurance-paragraph1")}</p>

      <ul>
        <li>
          <a href="/contact-us" target="_blank" rel="noopener noreferrer">
            <strong>
              {t("tasks.group3.health-insurance-list1-item1-bold")}
            </strong>
          </a>{" "}
          {t("tasks.group3.health-insurance-list1-item1")}
        </li>
        <li>
          <a href="/videolibrary" target="_blank" rel="noopener noreferrer">
            <strong>
              {t("tasks.group3.health-insurance-list1-item2-bold")}
            </strong>
          </a>{" "}
          {t("tasks.group3.health-insurance-list1-item2")}
        </li>
      </ul>
    </div>
  );
};

export const GetSwissInsuranceDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.group3.swiss-insurance-paragraph1")}</p>

      <ul>
        <li>
          <a href="/contact-us" target="_blank" rel="noopener noreferrer">
            <strong>
              {t("tasks.group3.swiss-insurance-list1-item1-bold")}
            </strong>
          </a>{" "}
          {t("tasks.group3.swiss-insurance-list1-item1")}
        </li>
        <li>
          <strong>{t("tasks.group3.swiss-insurance-list1-item2-bold")}</strong>{" "}
          {t("tasks.group3.swiss-insurance-list1-item2")}
        </li>
        <li>
          <strong>{t("tasks.group3.swiss-insurance-list1-item3-bold")}</strong>{" "}
          {t("tasks.group3.swiss-insurance-list1-item3")}
        </li>
      </ul>
    </div>
  );
};

export const ChangeDriverLicenseDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.group3.change-driver-license-paragraph1")}</p>

      <ul>
        <li>
          <strong>
            {t("tasks.group3.change-driver-license-list1-item1-bold")}
          </strong>{" "}
          {t("tasks.group3.change-driver-license-list1-item1")}
        </li>
        <li>
          <strong>
            {t("tasks.group3.change-driver-license-list1-item2-bold")}
          </strong>{" "}
          {t("tasks.group3.change-driver-license-list1-item2")}
        </li>
      </ul>
    </div>
  );
};

export const EBankingTwintDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.group3.ebanking-twint-paragraph1")}</p>
      <ul>
        <li>
          <strong> {t("tasks.group3.ebanking-twint-list1-item1-bold")}</strong>{" "}
          {t("tasks.group3.ebanking-twint-list1-item1")}
        </li>

        <li>
          <strong> {t("tasks.group3.ebanking-twint-list1-item2-bold")}</strong>{" "}
          {t("tasks.group3.ebanking-twint-list1-item2")}
        </li>
      </ul>
    </div>
  );
};

export const InformBanksOldCountryDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.group3.inform-banks-old-country-paragraph1")}</p>
      <li>
        <strong>
          {t("tasks.group3.inform-banks-old-country-list1-item1-bold")}
        </strong>{" "}
        {t("tasks.group3.inform-banks-old-country-list1-item1")}
      </li>
      <li>
        <strong>
          {t("tasks.group3.inform-banks-old-country-list1-item2-bold")}
        </strong>{" "}
        {t("tasks.group3.inform-banks-old-country-list1-item2")}
      </li>
    </div>
  );
};

export const MobilityDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.group3.mobility-paragraph1")}</p>

      <ul>
        <li>
          <a href="/videolibrary" target="_blank" rel="noopener noreferrer">
            <strong> {t("tasks.group3.mobility-list1-item1-bold")} </strong>
          </a>{" "}
          {t("tasks.group3.mobility-list1-item1")}
        </li>
        <li>
          <a
            href="https://www.sbb.ch/de/billette-angebote/abos/halbtax.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong> {t("tasks.group3.mobility-list1-item2-bold")} </strong>{" "}
          </a>{" "}
          {t("tasks.group3.mobility-list1-item2")}
        </li>
        <li>
          <a
            href="https://www.swisspass.ch/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong> {t("tasks.group3.mobility-list1-item3-bold")} </strong>
          </a>{" "}
          {t("tasks.group3.mobility-list1-item3")}
        </li>
        <li>
          <strong> {t("tasks.group3.mobility-list1-item4-bold")} </strong>{" "}
          {t("tasks.group3.mobility-list1-item4")}
        </li>
        <li>
          <strong> {t("tasks.group3.mobility-list1-item5-bold")} </strong>
          {t("tasks.group3.mobility-list1-item5")}
        </li>
      </ul>
    </div>
  );
};

export const SupermarketsDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.group3.supermarkets-paragraph1")}</p>

      <ul>
        <li>
          <strong> {t("tasks.group3.supermarkets-list1-item1-bold")} </strong>
          {t("tasks.group3.supermarkets-list1-item1")}
        </li>

        <li>
          <strong> {t("tasks.group3.supermarkets-list1-item2-bold")} </strong>
          {t("tasks.group3.supermarkets-list1-item2")}
        </li>

        <li>
          <strong> {t("tasks.group3.supermarkets-list1-item3-bold")} </strong>
          {t("tasks.group3.supermarkets-list1-item3")}
        </li>

        <li>
          <strong> {t("tasks.group3.supermarkets-list1-item4-bold")} </strong>
          {t("tasks.group3.supermarkets-list1-item4")}
        </li>
      </ul>
    </div>
  );
};

export const ChildcareDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.group3.childcare-paragraph1")}</p>

      <ul>
        <li>
          <strong> {t("tasks.group3.childcare-list1-item1-bold")}</strong>{" "}
          {t("tasks.group3.childcare-list1-item1")}
        </li>
        <li>
          <strong> {t("tasks.group3.childcare-list1-item2-bold")}</strong>{" "}
          {t("tasks.group3.childcare-list1-item2")}
        </li>
        <li>
          <strong> {t("tasks.group3.childcare-list1-item3-bold")}</strong>{" "}
          {t("tasks.group3.childcare-list1-item3")}
        </li>
      </ul>
    </div>
  );
};

export const EducationSystemDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.group3.education-system-paragraph1")}</p>

      <ul>
        <li>
          <strong>{t("tasks.group3.education-system-item1-bold")}</strong>{" "}
          {t("tasks.group3.education-system-item1")}
        </li>
        <li>{t("tasks.group3.education-system-item2")}</li>
      </ul>
    </div>
  );
};

export const OnlineShoppingDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.group3.online-shopping-paragraph1")}</p>
      <ul>
        <li>
          <a
            href="https://www.galaxus.ch/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong>Galaxus</strong>
          </a>
          <strong> / </strong>
          <a
            href="https://www.digitec.ch/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong>Digitec:</strong>
          </a>{" "}
          {t("tasks.group3.online-shopping-list1-item1")}
        </li>
        <li>
          <a
            href="https://www.tutti.ch/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong>Tutti</strong>
          </a>
          <strong> / </strong>
          <a
            href="https://www.ricardo.ch/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong>Ricardo:</strong>
          </a>{" "}
          {t("tasks.group3.online-shopping-list1-item2")}
        </li>
      </ul>
    </div>
  );
};

export const SerafeDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.group3.serafe-paragraph1")}</p>
    </div>
  );
};

export const WasteDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>
        {t("tasks.group3.waste-paragraph1")}

        <ul>
          <li>
            <strong> {t("tasks.group3.waste-list1-item1-bold")} </strong>
            {t("tasks.group3.waste-list1-item1")}
          </li>

          <li>
            <strong> {t("tasks.group3.waste-list1-item2-bold")} </strong>
            {t("tasks.group3.waste-list1-item2")}
          </li>

          <li>
            <strong> {t("tasks.group3.waste-list1-item3-bold")} </strong>
            {t("tasks.group3.waste-list1-item3")}
          </li>
        </ul>
      </p>
    </div>
  );
};

export const SwissPostalSystemDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.group3.postal-system-paragraph1")} </p>

      <ul>
        <li>
          <strong>{t("tasks.group3.postal-system-list1-item1-bold")} </strong>{" "}
          {t("tasks.group3.postal-system-list1-item1")}
        </li>
        <li>
          <strong>{t("tasks.group3.postal-system-list1-item2-bold")}</strong>{" "}
          {t("tasks.group3.postal-system-list1-item2")}
        </li>
        <li>
          <strong>{t("tasks.group3.postal-system-list1-item3-bold")}</strong>{" "}
          {t("tasks.group3.postal-system-list1-item3")}
        </li>
       
      </ul>
    </div>
  );
};

export const RegisterCarDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.group3.register-car-paragraph1")} </p>

      <ul>
        <li>
          <strong>{t("tasks.group3.register-car-list1-item1-bold")} </strong>{" "}
          {t("tasks.group3.register-car-list1-item1")}
        </li>
        <li>
          <strong>{t("tasks.group3.register-car-list1-item2-bold")}</strong>{" "}
          {t("tasks.group3.register-car-list1-item2")}
        </li>
        <li>
          <strong>{t("tasks.group3.register-car-list1-item3-bold")} </strong>
        </li>
      </ul>
    </div>
  );
};
