import React from "react";
import styles from "../../../styles/Company Dashboard styles/Contact Person Relotu styles/ContactPersonRelotu.module.css";
import avatarPlaceholder from "../../../assets/avatarPlaceholder.png";
import { FcBusinessman, FcPhone, FcCalendar, FcGlobe, FcInvite, FcSupport, FcContacts } from "react-icons/fc";
import { Link } from "react-router-dom";

const ContactPersonRelotu: React.FC = () => {
  return (
    <div className={styles.contactPersonRelotuContainer}>
      {/* Foto de Perfil */}
      <div className={styles.contactPersonRelotuImageContainer}>
        <img
          src={avatarPlaceholder}
          alt="Frederik Limberg Profile"
          className={styles.contactPersonRelotuImage}
          onError={(e) => (e.currentTarget.src = avatarPlaceholder)}
        />
      </div>

      {/* Información Básica */}
      <div className={styles.contactPersonRelotuBasicInfo}>
        <h3 className={styles.contactPersonRelotuName}>Frederik</h3>
        <p className={styles.contactPersonRelotuPosition}>Relotu Co-founder</p>
      </div>

      {/* Descripción de Frederik */}
      <div className={styles.contactPersonRelotuDescription}>
        <p>
          <FcBusinessman className={styles.contactPersonRelotuIcon} /> Hi there! I’m Frederik, your main
          point of contact at Relotu. My mission is to make your journey in finding the right talent
          as smooth and effective as possible. Together, we’ll tackle the labor shortage and bring top
          talent to your team.
        </p>
        <p>
          <FcGlobe className={styles.contactPersonRelotuIcon} /> Having relocated myself several times in my life, I know how overwhelming, but also exciting, it can be. I'm here to make this journey easier for you.
        </p>
        <p>
        <FcSupport className={styles.contactPersonRelotuIcon} />I’m here to assist, whether it’s by providing insights on relocation trends, guiding you through our services, or just being a quick call away for any support. Let’s make a difference together!
        </p>
      </div>

      {/* Detalles de Contacto */}
      <div className={styles.contactPersonRelotuDetails}>
        <p className={styles.contactPersonRelotuDetail}>
          <FcContacts className={styles.contactPersonRelotuIcon} /> <strong>Email:</strong>{" "}
          <a href="mailto:frederik@relotu.com" className={styles.contactPersonRelotuLink}>
            frederik@relotu.com
          </a>
        </p>
      </div>

      {/* Contacto Alternativo y Agendar Llamada */}
      <div className={styles.contactPersonRelotuAdditional}>
        <p className={styles.contactPersonRelotuAdditionalText}>
          For any immediate support or questions, please reach out to us anytime. 
          We’re here to support your talent search and help your business thrive. 
          You can also contact us at{" "}
          <a href="mailto:info@relotu.com" className={styles.contactPersonRelotuLink}>
            info@relotu.com
          </a>
          Or schedule a call using Calendly.
        </p>
        <button className={styles.contactPersonRelotuScheduleButton}>
          <Link to="/contact-us">
          Schedule a Call
          </Link>
        </button>
      </div>
    </div>
  );
};

export default ContactPersonRelotu;
