import React from "react";
import { useTranslation } from "react-i18next";

// TASK IMPORTANT DOCUMENTS READY
export const RetirementDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");

  return (
    <div>
      <p>
        {t("tasks.group4.retirement-paragraph1")}{" "}
        <a href="/contact-us" target="_blank" rel="noopener noreferrer">
          <strong>{t("tasks.group4.retirement-paragraph1-link")}</strong>
        </a>
      </p>
    </div>
  );
};

// TASK TAX
export const TaxDeclarationDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>
        {t("tasks.group4.tax-declaration-paragraph1")}{" "}
        <a href="/contact-us" target="_blank" rel="noopener noreferrer">
          <strong>{t("tasks.group4.tax-declaration-paragraph1-link")}</strong>
        </a>
      </p>
      <ul>
        <li>
          <a href="/videolibrary" target="_blank" rel="noopener noreferrer">
            <strong>
              {t("tasks.group4.tax-declaration-list1-item1-bold")}
            </strong>
          </a>{" "}
          {t("tasks.group4.tax-declaration-list1-item1")}
        </li>
      </ul>
    </div>
  );
};
