// src/data/videosData.ts

export interface Video {
  id: number;
  title: {
    en: string;
    de: string;
  };
  url: {
    en: string;
    de: string;
  };
  topic: string;
}

// Export the videos array with translations
export const videos: Video[] = [
  {
    id: 1,
    title: {
      en: "Swiss Residence Permit: An Overview",
      de: "Aufenthaltsbewilligung Schweiz: Ein Überblick",
    },
    url: {
      en: "https://www.youtube.com/embed/9h6KfuhW3f8?si=iKbgzVATCv4pFfEv",
      de: "https://www.youtube.com/embed/dVp9EcPaM3k?si=RWBvDfjr5b7TEGcu",
    },
    topic: "Working Permits",
  },
  {
    id: 2,
    title: {
      en: "(Coming soon) Tips for Finding an Apartment in Switzerland",
      de: "Tipps für die Wohnungssuche in der Schweiz",
    },
    url: {
      en: "https://www.youtube.com/embed/ENGLISH_URL_2?si=_-c4R7kgLVM_n63v",
      de: "https://www.youtube.com/embed/nGn_dpfxkik?si=_-c4R7kgLVM_n63v",
    },
    topic: "Apartment Search",
  },
  {
    id: 3,
    title: {
      en: "Apartment hunting: Required documents",
      de: "Wohnungssuche: Benötigte Unterlagen",
    },
    url: {
      en: "https://www.youtube.com/embed/u2GUpENTCNk?si=dKrt5jMXj0ILamSv",
      de: "https://www.youtube.com/embed/UMmJy1O5mL0?si=d_a20S2ceBr7WJ_q",
    },
    topic: "Apartment Search",
  },
  {
    id: 4,
    title: {
      en: "Health insurance: Basic and supplementary insurance",
      de: "Krankenversicherung Schweiz Grund- und Zusatzversicherung",
    },
    url: {
      en: "https://www.youtube.com/embed/y-3J6AdB4OY?si=jeyQrcoqniUC6fMp",
      de: "https://www.youtube.com/embed/2Nx8CGWBBhc?si=htSUceRAbiKQNJng",
    },
    topic: "Insurance",
  },
  {
    id: 5,
    title: {
      en: "Health Insurance: General Information",
      de: "Krankenversicherung Schweiz Generelle Infos",
    },
    url: {
      en: "https://www.youtube.com/embed/uEwZfdLYKqg?si=6XxNE0YzANP0z0uv",
      de: "https://www.youtube.com/embed/0RlsS0RvQrY?si=8BIF8j_MAO6V89UB",
    },
    topic: "Insurance",
  },
  {
    id: 6,
    title: {
      en: "Importing Household Goods into Switzerland",
      de: "Umzugsgut in die Schweiz einführen",
    },
    url: {
      en: "https://www.youtube.com/embed/WW3RsRraaB4?si=Qed7LBMs5vGO2_cy",
      de: "https://www.youtube.com/embed/aNHkylBfUfs?si=Rvfq8MLNs0zJ87xb",
    },
    topic: "General Relocation",
  },
  {
    id: 7,
    title: {
      en: "Importing Cats into Switzerland",
      de: "Katzen in die Schweiz einführen",
    },
    url: {
      en: "https://www.youtube.com/embed/pJtFmnHVssY?si=Ri_q0WPE6TkMCy3G",
      de: "https://www.youtube.com/embed/H_aB1Xa0VaY?si=vgjL26Bin_jfxvlJ",
    },
    topic: "Pets",
  },
  {
    id: 8,
    title: {
      en: "Importing Dogs into Switzerland",
      de: "Hunde in die Schweiz einführen",
    },
    url: {
      en: "https://www.youtube.com/embed/elQ5kYIRB_U?si=VTiEf5IQT2MrSM5P",
      de: "https://www.youtube.com/embed/Hm0n7Bnhals?si=6eVlFFZAnVs3L-eT",
    },
    topic: "Pets",
  },
  {
    id: 9,
    title: {
      en: "Public Transport in Switzerland",
      de: "Öffentlicher Nahverkehr in der Schweiz",
    },
    url: {
      en: "https://www.youtube.com/embed/DXt3fDErofI?si=20n9WDTfBm3xoVKE",
      de: "https://www.youtube.com/embed/U_FRKGpgxD8?si=P98baV7RcRC9QK-S",
    },
    topic: "Public Transport",
  },
  {
    id: 10,
    title: {
      en: "Cross Border-Commuter: Briefly explained",
      de: "Grenzgänger: Das Wichtigste kurz erklärt",
    },
    url: {
      en: "https://www.youtube.com/embed/td6502mcmPg?si=WauhJ_0kcwPKZhZ_",
      de: "https://www.youtube.com/embed/PeOLgXtescA?si=olQFyZCL5LeKh9da",
    },
    topic: "Commuting",
  },
  {
    id: 11,
    title: {
      en: "Diploma Recognition for Nurses & other Medical Professions",
      de: "Diplomanerkennung von Pflegenden & anderen medizinischen Berufen",
    },
    url: {
      en: "https://www.youtube.com/embed/CCiFF_F76ZM?si=3PZ_f8hLRH7sup67",
      de: "https://www.youtube.com/embed/83heKqLrCxQ?si=eLI3xBKygs9IEti-",
    },
    topic: "Diploma Recognition",
  },
  {
    id: 12,
    title: {
      en: "Diploma Recognition for Doctors",
      de: "Diplomanerkennung als Arzt oder Ärztin",
    },
    url: {
      en: "https://www.youtube.com/embed/Ma-cM7mqDfg?si=jLNmPvJ9xN3tYq8d",
      de: "https://www.youtube.com/embed/rWfHK06eSsM?si=gqQj44dZxSVFZoYP",
    },
    topic: "Diploma Recognition",
  },
  {
    id: 13,
    title: {
      en: "Tax at source & Statutory assessment in Switzerland",
      de: "Quellensteuer & ordentliche Steuern in der Schweiz",
    },
    url: {
      en: "https://www.youtube.com/embed/EaJJr9WY1gE?si=8CdaaI9a7aYOJHAZ",
      de: "https://www.youtube.com/embed/NA6_5zDjLpo?si=u3GqaXoZ9cA06vgO",
    },
    topic: "Taxes",
  },
  {
    id: 14,
    title: {
      en: "Taxes in Switzerland: General Information",
      de: "Steuern in der Schweiz: Generelle Infos",
    },
    url: {
      en: "https://www.youtube.com/embed/EccbKcpeXGk?si=1B6TLr4wC13gsN-j",
      de: "https://www.youtube.com/embed/Dr2_CBoTLRw?si=gTqNPdIG8NbfvJUi",
    },
    topic: "Taxes",
  },
  {
    id: 15,
    title: {
      en: "Swiss Residence Permit: Explained in Detail",
      de: "Aufenthaltsbewilligung Schweiz: Genauer erklärt",
    },
    url: {
      en: "https://www.youtube.com/embed/c-yT8uy7iic?si=Rjwz3TPDM-b-4Zde",
      de: "https://www.youtube.com/embed/xJZQY05a1Jg?si=y3yE6TbpMIZJ57nz",
    },
    topic: "Working Permits",
  },
];
