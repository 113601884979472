import React, { useState, useEffect } from "react";
import styles from "../../styles/Customer Journey styles/CandidateCustomer.module.css";
import defaultAvatar from "../../assets/avatarPlaceholder.png";
import switzerland1 from "../../assets/ch-img1.jpeg";
import switzerland2 from "../../assets/ch-img2.jpeg";
import switzerland3 from "../../assets/ch-img3.jpeg";
import switzerland4 from "../../assets/ch-img4.jpg";
import switzerland5 from "../../assets/ch-img4.jpg";
import {
  FaCheckCircle,
  FaChevronDown,
  FaChevronUp,
  FaQuestionCircle,
  FaRecordVinyl,
  FaVideo,
  FaPhoneAlt,
  FaSpotify,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface Step {
  id: number;
  key: string;
  title: string;
  description: string;
  completed: boolean;
  badge?: string;
  image: string;
  completedText: string;
  actions?: string[];
  icon?: string;
}

interface Notification {
  id: number;
  message: string;
  image?: string;
}

interface IndividualData {
  name: string;
  moveInfo: {
    moveType: string;
  };
  avatarUrl?: string;
}

interface CompanyData {
  name: string;
  avatar?: string;
}

const CandidateCustomer: React.FC = () => {
  const { t, i18n } = useTranslation("global");

  const [steps, setSteps] = useState<Step[]>([]);
  const [activeStep, setActiveStep] = useState<number | null>(null);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [individualName, setIndividualName] = useState<string>("");
  const [moveType, setMoveType] = useState<string>("");
  const [companyName, setCompanyName] = useState<string>("");
  const [avatarUrl, setAvatarUrl] = useState<string>(""); //(defaultAvatar);

  const [showSampleQuestions, setShowSampleQuestions] =
    useState<boolean>(false);
  const [showSampleVideoTopics, setShowSampleVideoTopics] =
    useState<boolean>(false);
  const [showBookCall, setShowBookCall] = useState<boolean>(false);

  const getToken = () => {
    return localStorage.getItem("token") || "";
  };

  useEffect(() => {
    const fetchData = async () => {
      const token = getToken();
      try {
        const responseIndividual = await fetch(
          "https://api.relotu.com/individuals",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "x-relotu-access": token,
            },
          }
        );

        if (responseIndividual.ok) {
          const dataIndividual: IndividualData =
            await responseIndividual.json();

          setIndividualName(dataIndividual.name);
          setMoveType(dataIndividual.moveInfo.moveType);
          if (dataIndividual.avatarUrl) {
            setAvatarUrl(dataIndividual.avatarUrl);
          }
        } else {
          console.error("Error fetching individual data");
          return;
        }

        const responseCompany = await fetch(
          "https://api.relotu.com/individuals/company",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "x-relotu-access": token,
            },
          }
        );

        if (responseCompany.ok) {
          const dataCompany: CompanyData = await responseCompany.json();
          if (dataCompany.name) {
            setCompanyName(dataCompany.name);
          }
          if (dataCompany.avatar) {
          }
        } else {
          console.error("Error fetching company data");
          return;
        }
      } catch (error) {
        console.error("Error:", error);
        return;
      }
    };

    fetchData();
  }, []);

  const generateSteps = () => {
    return [
      {
        id: 1,
        key: "application",
        title: t("candidateCustomer.steps.application.title"),
        description: t("candidateCustomer.steps.application.description"),
        completed: true,
        badge: "Applicant",
        image: switzerland1,
        completedText: t("candidateCustomer.steps.application.completedText"),
        actions: t("candidateCustomer.steps.application.actions", {
          returnObjects: true,
        }) as string[],
        icon: "📝",
      },
      {
        id: 2,
        key: "askQuestions",
        title: t("candidateCustomer.steps.askQuestions.title"),
        description: t("candidateCustomer.steps.askQuestions.description"),
        completed: false,
        badge: "ChatExplorer",
        image: switzerland2,
        completedText: t("candidateCustomer.steps.askQuestions.completedText"),
        actions: t("candidateCustomer.steps.askQuestions.actions", {
          returnObjects: true,
        }) as string[],
        icon: "💬",
      },
      {
        id: 3,
        key: "accessVideos",
        title: t("candidateCustomer.steps.accessVideos.title"),
        description: t("candidateCustomer.steps.accessVideos.description"),
        completed: false,
        badge: "VideoLearner",
        image: switzerland3,
        completedText: t("candidateCustomer.steps.accessVideos.completedText"),
        actions: t("candidateCustomer.steps.accessVideos.actions", {
          returnObjects: true,
        }) as string[],
        icon: "🎥",
      },
      {
        id: 4,
        key: "bookCall",
        title: t("candidateCustomer.steps.bookCall.title"),
        description: t("candidateCustomer.steps.bookCall.description"),
        completed: false,
        badge: "Advised",
        image: switzerland4,
        completedText: t("candidateCustomer.steps.bookCall.completedText"),
        actions: t("candidateCustomer.steps.bookCall.actions", {
          returnObjects: true,
        }) as string[],
        icon: "📞",
      },
      {
        id: 5,
        key: "completeInterview",
        title: t("candidateCustomer.steps.completeInterview.title"),
        description: t("candidateCustomer.steps.completeInterview.description"),
        completed: false,
        badge: "Interviewer",
        image: switzerland5,
        completedText: t(
          "candidateCustomer.steps.completeInterview.completedText"
        ),
        actions: t("candidateCustomer.steps.completeInterview.actions", {
          returnObjects: true,
        }) as string[],
        icon: "🎤",
      },
    ];
  };

  useEffect(() => {
    setSteps((prevSteps) => {
      const newSteps = generateSteps();

      return newSteps.map((step) => {
        const prevStep = prevSteps.find((s) => s.id === step.id);
        return {
          ...step,
          completed: prevStep ? prevStep.completed : step.completed,
        };
      });
    });
  }, [i18n.language, companyName]);

  useEffect(() => {
    if (moveType.toLowerCase() === "candidate") {
      setSteps((prevSteps) =>
        prevSteps.map((step) =>
          step.id === 1
            ? { ...step, completed: true }
            : { ...step, completed: false }
        )
      );
    } else {
      setSteps((prevSteps) =>
        prevSteps.map((step) => ({ ...step, completed: true }))
      );
    }
  }, [moveType]);

  const toggleStepCompletion = (id: number) => {
    setSteps((prevSteps) =>
      prevSteps.map((step) =>
        step.id === id ? { ...step, completed: !step.completed } : step
      )
    );

    const step = steps.find((s) => s.id === id);
    if (step) {
      if (!step.completed) {
        addNotification(
          t("candidateCustomer.notifications.badgeObtained", {
            badge: step.badge,
          }),
          step.image
        );
      } else {
        addNotification(
          t("candidateCustomer.notifications.badgeRemoved", {
            badge: step.badge,
          }),
          step.image
        );
      }
    }
  };

  const toggleExpand = (id: number) => {
    setActiveStep(activeStep === id ? null : id);
  };

  const addNotification = (message: string, image?: string) => {
    const id = Date.now();
    setNotifications((prev) => [...prev, { id, message, image }]);

    setTimeout(() => {
      setNotifications((prev) => prev.filter((n) => n.id !== id));
    }, 3000);
  };

  const calculateProgress = () => {
    const completedSteps = steps.filter((step) => step.completed).length;
    return Math.round((completedSteps / steps.length) * 100);
  };

  if (!individualName || !moveType || !companyName) {
    return null;
  }

  return (
    <div className={styles.candidateCustomer}>
      {/* Notificaciones */}
      <div className={styles.notifications}>
        {notifications.map((notification) => (
          <div key={notification.id} className={styles.notification}>
            <span>{notification.message}</span>
            {notification.image && (
              <img
                src={notification.image}
                alt="Switzerland"
                className={styles.notificationImage}
              />
            )}
          </div>
        ))}
      </div>

      {/* Encabezado con Box */}
      <header className={styles.header}>
        <div className={styles.headerBox}>
          {avatarUrl && (
            <img src={avatarUrl} alt="Guide Avatar" className={styles.avatar} />
          )}
          <h1 className={styles.title}>
            <span className={styles.titleIcon}>🚀</span>
            {t("candidateCustomer.header.welcome")}
          </h1>
          <p className={styles.motivationalMessage}>
            {individualName
              ? t("candidateCustomer.header.motivationalMessage", {
                  individualName,
                  companyName,
                })
              : ""}
          </p>
        </div>
      </header>

      {/* Rastreador de Progreso */}
      <section className={styles.progressTracker}>
        <h2 className={styles.sectionTitle}>
          {t("candidateCustomer.progressTracker.title")}
        </h2>
        <div className={styles.progressBarContainer}>
          <div
            className={styles.progressBar}
            aria-valuenow={calculateProgress()}
            aria-valuemin={0}
            aria-valuemax={100}
            role="progressbar"
          >
            <div
              className={styles.progress}
              style={{ width: `${calculateProgress()}%` }}
            ></div>
          </div>
          <span className={styles.progressLabel}>
            {t("candidateCustomer.progressTracker.progressLabel", {
              progress: calculateProgress(),
            })}
          </span>
        </div>
        <div className={styles.badges}>
          {steps.map(
            (step) =>
              step.completed &&
              step.badge && (
                <span key={step.id} className={styles.badge}>
                  {step.badge}
                </span>
              )
          )}
        </div>
      </section>

      {/* Pasos Interactivos */}
      <section className={styles.stepsSection}>
        <h2 className={styles.sectionTitle}>
          {t("candidateCustomer.nextSteps.title")}
        </h2>
        <div className={styles.gridContainer}>
          {steps.map((step) => (
            <div key={step.id} className={styles.stepCard}>
              <div className={styles.stepHeader}>
                <button
                  className={styles.checkButton}
                  onClick={() => toggleStepCompletion(step.id)}
                  aria-label={
                    step.completed
                      ? t(
                          `candidateCustomer.steps.${step.key}.toggleIncomplete`,
                          { title: step.title }
                        )
                      : t(
                          `candidateCustomer.steps.${step.key}.toggleComplete`,
                          { title: step.title }
                        )
                  }
                  title={
                    step.completed
                      ? t(
                          `candidateCustomer.steps.${step.key}.toggleIncomplete`,
                          { title: step.title }
                        )
                      : t(
                          `candidateCustomer.steps.${step.key}.toggleComplete`,
                          { title: step.title }
                        )
                  }
                >
                  {step.completed ? (
                    <FaCheckCircle className={styles.checkIcon} />
                  ) : (
                    <FaCheckCircle className={styles.uncheckedIcon} />
                  )}
                </button>
                <div
                  className={styles.leftHeader}
                  onClick={() => toggleStepCompletion(step.id)}
                  role="button"
                  tabIndex={0}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") toggleStepCompletion(step.id);
                  }}
                  aria-label={
                    step.completed
                      ? t(
                          `candidateCustomer.steps.${step.key}.toggleIncomplete`,
                          { title: step.title }
                        )
                      : t(
                          `candidateCustomer.steps.${step.key}.toggleComplete`,
                          { title: step.title }
                        )
                  }
                >
                  <h3 className={styles.stepTitle}>
                    <span className={styles.icon}>{step.icon}</span>{" "}
                    {step.title}
                  </h3>
                </div>
                <div className={styles.rightHeader}>
                  {/* Botón para expandir/colapsar */}
                  <button
                    className={styles.toggleButton}
                    onClick={() => toggleExpand(step.id)}
                    aria-label={
                      activeStep === step.id
                        ? t("candidateCustomer.buttons.collapse")
                        : t("candidateCustomer.buttons.expand")
                    }
                  >
                    {activeStep === step.id ? (
                      <FaChevronUp />
                    ) : (
                      <FaChevronDown />
                    )}
                  </button>
                </div>
              </div>

              {activeStep === step.id && (
                <div className={styles.stepDetails}>
                  {step.completed ? (
                    <div className={styles.completed}>
                      <FaCheckCircle className={styles.checkIcon} />
                      <p>{step.completedText}</p>
                    </div>
                  ) : (
                    <>
                      <p>{step.description}</p>

                      {/* Sección Expandible para Preguntas Recomendadas en el Paso 2 */}
                      {step.id === 2 && (
                        <div className={styles.sampleQuestionsSection}>
                          <button
                            className={styles.sampleQuestionsToggle}
                            onClick={() =>
                              setShowSampleQuestions(!showSampleQuestions)
                            }
                            aria-expanded={showSampleQuestions}
                            aria-controls="sample-questions"
                          >
                            {showSampleQuestions
                              ? t(
                                  "candidateCustomer.sampleQuestions.toggleHide"
                                )
                              : t(
                                  "candidateCustomer.sampleQuestions.toggleShow"
                                )}
                          </button>
                          {showSampleQuestions && (
                            <ul
                              id="sample-questions"
                              className={styles.sampleQuestionsList}
                            >
                              {(
                                t(
                                  "candidateCustomer.sampleQuestions.questions",
                                  { returnObjects: true }
                                ) as string[]
                              ).map((question: string, index: number) => (
                                <li key={index}>{question}</li>
                              ))}
                            </ul>
                          )}
                        </div>
                      )}

                      {/* Sección Expandible para Temas de Videos Recomendados en el Paso 3 */}
                      {step.id === 3 && (
                        <div className={styles.sampleVideoTopicsSection}>
                          <button
                            className={styles.sampleVideoTopicsToggle}
                            onClick={() =>
                              setShowSampleVideoTopics(!showSampleVideoTopics)
                            }
                            aria-expanded={showSampleVideoTopics}
                            aria-controls="sample-video-topics"
                          >
                            {showSampleVideoTopics
                              ? t(
                                  "candidateCustomer.sampleVideoTopics.toggleHide"
                                )
                              : t(
                                  "candidateCustomer.sampleVideoTopics.toggleShow"
                                )}
                          </button>
                          {showSampleVideoTopics && (
                            <ul
                              id="sample-video-topics"
                              className={styles.sampleVideoTopicsList}
                            >
                              {(
                                t(
                                  "candidateCustomer.sampleVideoTopics.topics",
                                  { returnObjects: true }
                                ) as string[]
                              ).map((topic: string, index: number) => (
                                <li key={index}>{topic}</li>
                              ))}
                            </ul>
                          )}
                        </div>
                      )}

                      {/* Sección Expandible para Reservar una Llamada en el Paso 4 */}
                      {step.id === 4 && (
                        <div className={styles.bookCallSection}>
                          <button
                            className={styles.bookCallToggle}
                            onClick={() => setShowBookCall(!showBookCall)}
                            aria-expanded={showBookCall}
                            aria-controls="book-call"
                          >
                            {showBookCall
                              ? t(
                                  "candidateCustomer.bookCallSection.toggleHide"
                                )
                              : t(
                                  "candidateCustomer.bookCallSection.toggleShow"
                                )}
                          </button>
                          {showBookCall && (
                            <div
                              id="book-call"
                              className={styles.bookCallInstructions}
                            >
                              <p>
                                {t(
                                  "candidateCustomer.bookCallSection.instructions"
                                )}
                              </p>
                            </div>
                          )}
                        </div>
                      )}

                      {/* Botones específicos para ciertos pasos fuera de las listas */}
                      {step.id === 2 && (
                        <Link
                          to="/chat"
                          className={`${styles.actionButton} ${styles.prominentButton}`}
                          title={t("candidateCustomer.buttons.goToChat")}
                        >
                          <FaQuestionCircle className={styles.actionIcon} />
                          {t("candidateCustomer.buttons.goToChat")}
                        </Link>
                      )}
                      {step.id === 3 && (
                        <>
                          <div className={styles.buttonContainer}>
                            <Link
                              to="/videolibrary"
                              className={`${styles.actionButton} ${styles.prominentButton}`}
                              title={t(
                                "interestedCustomer.buttons.goToVideoLibrary"
                              )}
                            >
                              <FaVideo className={styles.actionIcon} />
                              {t("interestedCustomer.buttons.goToVideoLibrary")}
                            </Link>
                          </div>

                          <div className={styles.buttonContainer}>
                            <a
                              href="https://open.spotify.com/playlist/5Mpq6oljUiwcpWdliO8ZW9?si=119d0718f32340a2"
                              target="_blank"
                              rel="noopener noreferrer"
                              className={`${styles.actionButton} ${styles.prominentButton}`}
                              title={t(
                                "interestedCustomer.buttons.openSpotifyPlaylist"
                              )}
                              aria-label="Open Spotify Playlist"
                            >
                              <FaSpotify className={styles.actionIcon} />
                              {t(
                                "interestedCustomer.buttons.openSpotifyPlaylist"
                              )}
                            </a>
                          </div>
                        </>
                      )}
                      {step.id === 4 && (
                        <Link
                          to="/contact-us"
                          className={`${styles.actionButton} ${styles.prominentButton}`}
                          title={t("candidateCustomer.buttons.scheduleCall")}
                        >
                          <FaPhoneAlt className={styles.actionIcon} />
                          {t("candidateCustomer.buttons.scheduleCall")}
                        </Link>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default CandidateCustomer;
