// App.tsx
import ChatBotButton from "./components/Chat components/ChatBotButton";
import ChatBot from "./components/Chat components/ChatBot";
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NavBar from "./components/NavBar";
import LogInIndividuals from "./components/Authentication components/LogInIndividuals";
import LoginCompanies from "./components/Authentication components/LoginCompanies";
import CalendlyComponent from "./components/CalendlyComponent";
import LanguageSelector from "./components/LanguageSelector";
import Individuals from "./components/Individuals";
import { type } from "os";
import Registration from "./components/Authentication components/RegistrationCompanies";
import Footer from "./components/Footer";
import PrivacyPolicy from "./components/Legal components/PrivacyPolicy";
import Impressum from "./components/Legal components/Impressum";
import { EmailSentConfirmation } from "./components/Authentication components/EmailSentConfirmation";
import CookieHandler from "./components/Legal components/CookieHandler";
import LandingPage from "./components/Companies Landing Page components/LandingPage";
import MainPage from "./components/MainPage";
import ReactGA from "react-ga";
import CookiesSettings from "./components/Legal components/CookiesSettings";
import { useTranslation } from "react-i18next";
import ScrollToTop from "./components/ScrollToTop";
import RegistrationCompanies from "./components/Authentication components/RegistrationCompanies";
import FailValidationEmail from "./components/Authentication components/FailValidationEmail";
import SuccessfulValidationEmail from "./components/Authentication components/SuccesfulValidationEmail";
import RegisterUserTypeSelection from "./components/Authentication components/RegistrationUserTypeSelection";
import LoginUserTypeSelection from "./components/Authentication components/LoginUserTypeSelection";
import { ProtectedPage } from "./components/Authentication components/ProtectedPage";
import MultiStepSignUp from "./components/Authentication components/MultiStepSignUp";
import ChatApp from "./components/Chat components/ChatApp";
import CostOfLivingCalculator from "./components/Calculator components/CostCalculator";
import PrivateRoute from "./components/Authentication components/PrivateRoute";
import DashboardIndividuals from "./components/Dashboard components/DashboardIndividuals";
import TasksIndividuals from "./components/Tasks components/TasksIndividuals";
import TasksCompanies from "./components/Tasks components/TasksCompanies";
import PublicRoute from "./components/Authentication components/PublicRoute";
import RecoverPassword from "./components/Authentication components/RecoverPassword";
import ChangePassword from "./components/Authentication components/ChangePassword";
import DashboardCompanies from "./components/Dashboard components/DashboardCompanies";
import VideoLibraryIndividuals from "./components/VideoLibrary Components/VideoLibrary";
import PeopleInsights from "./components/People Insights components/PeopleInsights";
import CompanyProfile from "./components/Company Dashboard components/Profile components/CompanyProfile";
import TermsAndConditions from "./components/Legal components/TermsAndConditions";
import CustomerJourney from "./components/Customer Journey components/CustomerJourney";
import CuriositiesCalendar from "./components/Curiosities Calendar components/CuriositiesCalendar";
import CompanyDashboard from "./components/Company Dashboard components/CompanyDashboard";

const TRACKING_ID = "G-CC6QRK8MX8";
ReactGA.initialize(TRACKING_ID);

const App: React.FC = () => {
  const [t, i18n] = useTranslation("global");

  useEffect(() => {
    const storedLanguage = localStorage.getItem("selectedLanguage");
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);

  const [showNavFooter, setShowNavFooter] = useState(true);

  const [isChatOpen, setIsChatOpen] = useState(false);

  const toggleChat = () => setIsChatOpen(!isChatOpen);
  //const isPasswordValid = localStorage.getItem("isPassword") === "true";

  return (
    <>
      <ScrollToTop />
      {showNavFooter && <NavBar />}

      <Routes>
        {/*<Route
          path="/protected-registration"
          element={
            <ProtectedPage
              onProtectedPage={() => setShowNavFooter(false)}
              notOnProtectedPage={() => setShowNavFooter(true)}
            />
          }
        />
        */}
        <Route
          path="/email-sent-confirmation"
          element={
            <EmailSentConfirmation
              onProtectedPage={() => setShowNavFooter(false)}
              notOnProtectedPage={() => setShowNavFooter(true)}
            />
          }
        />
        <Route
          path="/"
          element={
            <PublicRoute>
              <MainPage />
            </PublicRoute>
          }
        />{" "}
        <Route path="/companies-landing-page" element={<LandingPage />} />
        <Route path="/individuals-landing-page" element={<Individuals />} />
        <Route
          path="/contact-us"
          element={
            <CalendlyComponent
              title={t("companies-landing-page.calendly-title-black")}
              boldText={t("companies-landing-page.calendly-paragraph-black")}
              normalText={t("companies-landing-page.calendly-paragraph-normal")}
              whatsappText={t("contact-us-movers.calendly-whatsapp")}
              whatsappLink="https://api.whatsapp.com/message/YTBSRAKLMEWIE1?autoload=1&app_absent=0"
              calendlyLink="https://calendly.com/relotu/45min"
            />
          }
        />
        <Route
          path="/login-organization"
          element={
            <PublicRoute>
              <LoginCompanies />
            </PublicRoute>
          }
        />
        <Route
          path="/login-individuals"
          element={
            <PublicRoute>
              <LogInIndividuals />
            </PublicRoute>
          }
        />
        <Route path="/registration" element={<RegisterUserTypeSelection />} />
        <Route
          path="/registration-organization"
          element={
            <PublicRoute>
              <RegistrationCompanies />
            </PublicRoute>
          }
        />
        <Route
          path="/registration-individuals"
          element={
            <PublicRoute>
              <MultiStepSignUp />
            </PublicRoute>
          }
        />
        <Route
          path="/recover-password"
          element={
            <PublicRoute>
              <RecoverPassword />
            </PublicRoute>
          }
        />
        <Route
          path="/change-password"
          element={
            <PublicRoute>
              <ChangePassword />
            </PublicRoute>
          }
        />
        {/* Rutas Protegidas */}
        <Route
          path="/dashboard-companies"
          element={
            <PrivateRoute>
              <DashboardCompanies />
            </PrivateRoute>
          }
          />

          {/* This is the good one below */}

        <Route
          path="/company-dashboard"
          element={
            <PrivateRoute>
              <CompanyDashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/my-journey"
          element={
            <PrivateRoute>
              <CustomerJourney />
            </PrivateRoute>
          }
        />
        <Route
          path="/company-profile"
          element={
            <PrivateRoute>
              <CompanyProfile />
            </PrivateRoute>
          }
        />
        {/*<Route
          path="/dashboard-individuals"
          element={
            <PrivateRoute>
              <DashboardIndividuals />
            </PrivateRoute>
          }
        />*/}
        <Route
          path="/chat"
          element={
            <PrivateRoute>
              <ChatApp />
            </PrivateRoute>
          }
        />
        <Route
          path="/cost-calculator"
          element={
            <PrivateRoute>
              <CostOfLivingCalculator />
            </PrivateRoute>
          }
        />
        <Route
          path="/curiosities-calendar"
          element={
            <PrivateRoute>
              <CuriositiesCalendar />
            </PrivateRoute>
          }
        />
        <Route
          path="/people-insights"
          element={
            <PrivateRoute>
              <PeopleInsights />
            </PrivateRoute>
          }
        />
        <Route
          path="/my-tasks"
          element={
            <PrivateRoute>
              <TasksIndividuals />
            </PrivateRoute>
          }
        />
        <Route
          path="/company-tasks"
          element={
            <PrivateRoute>
              <TasksCompanies />
            </PrivateRoute>
          }
        />
        <Route
          path="/videolibrary"
          element={
            <PrivateRoute>
              <VideoLibraryIndividuals />
            </PrivateRoute>
          }
        />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/cookies-settings" element={<CookiesSettings />} />
        <Route path="/impressum" element={<Impressum />} />
        <Route
          path="/success-validation"
          element={<SuccessfulValidationEmail />}
        />
        <Route path="/fail-validation" element={<FailValidationEmail />} />
      </Routes>

      <CookieHandler
        privacyPolicyLink="/privacy-policy"
        cookieSettingsLink="/cookies-settings"
      />
      {/*{showNavFooter && !isChatOpen && <ChatBotButton onClick={toggleChat} />}
      {isChatOpen && <ChatBot onClose={toggleChat} />}*/}
      {showNavFooter && <Footer />}
    </>
  );
};

export default App;
