import React from "react";
import { useTranslation } from "react-i18next";

// TASK IMPORTANT DOCUMENTS READY
export const WeeklyGeneralInformationDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");

  return (
    <div>
      <ul>
        <li>{t("tasks.weekly-commuter.general-information-list1-item1")} </li>
        <li>{t("tasks.weekly-commuter.general-information-list1-item2")}</li>
        <li>{t("tasks.weekly-commuter.general-information-list1-item3")}</li>
      </ul>
    </div>
  );
};

// TASK CERTIFICATE RESIDENCE
export const CertificateResidenceDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <ul>
        <li>
          {t("tasks.weekly-commuter.certificate-residence-list1-item1-part1")}{" "}
          <a
            href="https://www.formulare-bfinv.de/ffw/form/display.do?%24context=5927A66C179B6BDB71B3"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("tasks.weekly-commuter.certificate-residence-list1-item1-link")}{" "}
          </a>
          {t("tasks.weekly-commuter.certificate-residence-list1-item1-part2")}{" "}
        </li>
        <li>{t("tasks.weekly-commuter.certificate-residence-list1-item2")}</li>
      </ul>
      <ul style={{ listStyleType: "none" }}>
        <li>
          <a
            href="https://www.formulare-bfinv.de/ffw/form/display.do?%24context=5927A66C179B6BDB71B3"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("tasks.weekly-commuter.certificate-residence-list1-item3")}
          </a>
        </li>
      </ul>
    </div>
  );
};

// TASK WORK PERMIT
export const WeeklyCertificateResidenceDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.weekly-commuter.work-permit-paragraph1")}</p>
      <p>
        <strong>
          {t("tasks.weekly-commuter.work-permit-paragraph2-bold")}{" "}
        </strong>{" "}
        {t("tasks.weekly-commuter.work-permit-paragraph2")}
      </p>
      <p>
        {t("tasks.weekly-commuter.work-permit-paragraph3")}{" "}
        <a href="/videolibrary" target="_blank" rel="noopener noreferrer">
          {t("tasks.weekly-commuter.work-permit-paragraph3-link")}
        </a>
      </p>
    </div>
  );
};

// TASK BANK ACCOUNT

export const OpenBankAccountCommuterDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.weekly-commuter.open-bank-paragraph1")}</p>
    </div>
  );
};

// TASK PHONE ACCOUNT

export const MobilePhoneCommuterDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.weekly-commuter.mobile-phone-paragraph1")}</p>
    </div>
  );
};

// TASK HEALTH INSURANCE

export const WeeklyHealthInsuranceCommuterDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.weekly-commuter.health-insurance-paragraph1")}</p>

      <ul>
        <li>
          <strong>
            {t("tasks.weekly-commuter.health-insurance-list1-item1-bold")}{" "}
          </strong>
          {t("tasks.weekly-commuter.health-insurance-list1-item1")}
        </li>
        <li>
          <strong>
            {t("tasks.weekly-commuter.health-insurance-list1-item2-bold")}{" "}
          </strong>
          {t("tasks.weekly-commuter.health-insurance-list1-item2")}
        </li>
        <li>
          <strong>
            {t("tasks.weekly-commuter.health-insurance-list1-item3-bold")}{" "}
          </strong>
          {t("tasks.weekly-commuter.health-insurance-list1-item3")}
        </li>
      </ul>
    </div>
  );
};

// TASK INSURANCES FOR COMMUTERS

export const InsurancesCommuterDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <ul style={{ listStyleType: "none" }}>
        <li>
          <strong>
            {t("tasks.weekly-commuter.insurance-commuters-list1-item1-bold")}{" "}
          </strong>
          {t("tasks.weekly-commuter.insurance-commuters-list1-item1")}
        </li>
        <li>
          <strong>
            {t("tasks.weekly-commuter.insurance-commuters-list1-item2-bold")}{" "}
          </strong>
          {t("tasks.weekly-commuter.insurance-commuters-list1-item2")}
        </li>
        <li>
          <strong>
            {t("tasks.weekly-commuter.insurance-commuters-list1-item3-bold")}{" "}
          </strong>
          {t("tasks.weekly-commuter.insurance-commuters-list1-item3")}
        </li>
        <li>
          <strong>
            {t("tasks.weekly-commuter.insurance-commuters-list1-item4-bold")}{" "}
          </strong>
          {t("tasks.weekly-commuter.insurance-commuters-list1-item4")}
        </li>
        <li>
          <strong>
            {t("tasks.weekly-commuter.insurance-commuters-list1-item5-bold")}{" "}
          </strong>
          {t("tasks.weekly-commuter.insurance-commuters-list1-item5")}
        </li>
      </ul>
    </div>
  );
};

// TASK RETIREMENT FOR COMMUTERS

export const RetirementCommuterDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.weekly-commuter.retirement-paragraph1")}</p>
      <ul>
        <li>
          <strong>
            {t("tasks.weekly-commuter.retirement-list1-item1-bold")}
          </strong>
          {t("tasks.weekly-commuter.retirement-list1-item1")}
        </li>
        <li>
          <strong>
            {t("tasks.weekly-commuter.retirement-list1-item2-bold")}
          </strong>
          {t("tasks.weekly-commuter.retirement-list1-item2")}
        </li>
        <li>
          <strong>
            {t("tasks.weekly-commuter.retirement-list1-item3-bold")}
          </strong>
          {t("tasks.weekly-commuter.retirement-list1-item3")}
        </li>
      </ul>

      <p>
        {t("tasks.weekly-commuter.retirement-paragraph2-part1")}{" "}
        <strong>{t("tasks.weekly-commuter.retirement-paragraph2-bold")}</strong>
        {t("tasks.weekly-commuter.retirement-paragraph2-part2")}
      </p>
      <p>
        <strong>{t("tasks.weekly-commuter.retirement-paragraph3-bold")}</strong>{" "}
        {t("tasks.weekly-commuter.retirement-paragraph3")}
      </p>
    </div>
  );
};

// TASK TAXATION

export const WeeklyTaxationCommuterDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.weekly-commuter.taxation-paragraph1")}</p>
      <p>
        {t("tasks.weekly-commuter.taxation-paragraph2")}{" "}
        <a href="/videolibrary" target="_blank" rel="noopener noreferrer">
          {t("tasks.weekly-commuter.taxation-paragraph2-link")}
        </a>
      </p>
    </div>
  );
};

// TASK APARTMENT SEARCH
export const WeeklyApartmentSearchDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");

  return (
    <div>
      <p>{t("tasks.weekly-commuter.apartment-search-paragraph1")}</p>

      <ul>
        <li>
          <a href="/videolibrary" target="_blank" rel="noopener noreferrer">
            <strong>
              {t("tasks.weekly-commuter.apartment-search-list1-item1-bold")}
            </strong>
          </a>{" "}
          {t("tasks.weekly-commuter.apartment-search-list1-item1-normal")}
        </li>
        <li>
          <strong>
            {t("tasks.weekly-commuter.apartment-search-list1-item2-bold")}
          </strong>{" "}
          {t("tasks.weekly-commuter.apartment-search-list1-item2-normal")}
        </li>
        <li>
          <strong>
            {t("tasks.weekly-commuter.apartment-search-list1-item3-bold")}
          </strong>{" "}
          <a
            href="https://www.homegate.ch/de"
            target="_blank"
            rel="noopener noreferrer"
          >
            Homegate
          </a>
          ,{" "}
          <a
            href="https://flatfox.ch/c/en/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Flatfox
          </a>
          ,{" "}
          <a
            href="https://www.newhome.ch/de/mieten/suchen/wohnung/ort-zuerich/liste"
            target="_blank"
            rel="noopener noreferrer"
          >
            Newhome
          </a>
        </li>
      </ul>
    </div>
  );
};

// TASK PICK UP PERMIT

export const WeeklyPickUpPermitDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.weekly-commuter.pick-up-permit-paragraph1")}</p>
    </div>
  );
};

// TASK REGISTER

export const WeeklyRegisterSwissDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.weekly-commuter.register-swiss-paragraph1")}</p>
      <p>{t("tasks.weekly-commuter.register-swiss-paragraph2")}</p>
    </div>
  );
};

// TASK PICK UP PERMIT

export const WeeklyOpenBankDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>
        {t("tasks.weekly-commuter.open-bank-paragraph1")}{" "}
        <a href="/contact-us" target="_blank" rel="noopener noreferrer">
          {t("tasks.weekly-commuter.open-bank-paragraph1-link")}
        </a>{" "}
        {t("tasks.weekly-commuter.open-bank-paragraph2")}
      </p>
    </div>
  );
};

// TASK CURRENCY EXCHANGE

export const WeeklyCurrencyExchangeDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.weekly-commuter.currency-exchange-paragraph1")}</p>

      <ul>
        <li>
          <strong>
            {t("tasks.weekly-commuter.currency-exchange-list1-item1-bold")}
          </strong>{" "}
          {t("tasks.weekly-commuter.currency-exchange-list1-item1-normal")}
        </li>
        <li>
          <strong>
            {t("tasks.weekly-commuter.currency-exchange-list1-item2-bold")}
          </strong>{" "}
          {t("tasks.weekly-commuter.currency-exchange-list1-item2-normal")}
        </li>
      </ul>
    </div>
  );
};

// TASK PHONE

export const WeeklyPhoneAndInternetDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.weekly-commuter.phone-internet-paragraph1")}</p>

      <ul>
        <li>
          <strong>
            {t("tasks.weekly-commuter.phone-internet-list1-item1-bold")}
          </strong>{" "}
          {t("tasks.weekly-commuter.phone-internet-list1-item1-normal")}
        </li>
        <li>
          <strong>
            {t("tasks.weekly-commuter.phone-internet-list1-item2-bold")}
          </strong>{" "}
          {t("tasks.weekly-commuter.phone-internet-list1-item2-normal")}
        </li>
      </ul>
    </div>
  );
};

// SWISS INSURANCE PHONE

export const WeeklyAfterHealthInsuranceDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.weekly-commuter.health-insurance-paragraph1")}</p>

      <ul>
        <li>
          <a href="/contact-us" target="_blank" rel="noopener noreferrer">
            <strong>
              {t("tasks.weekly-commuter.health-insurance-list1-item1-bold")}
            </strong>
          </a>{" "}
          {t("tasks.weekly-commuter.health-insurance-list1-item1")}
        </li>
        <li>
          <a href="/videolibrary" target="_blank" rel="noopener noreferrer">
            <strong>
              {t("tasks.weekly-commuter.health-insurance-list1-item2-bold")}
            </strong>
          </a>{" "}
          {t("tasks.weekly-commuter.health-insurance-list1-item2")}
        </li>
      </ul>
    </div>
  );
};

// SWISS INSURANCE PHONE

export const WeeklyGetSwissInsuranceDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.weekly-commuter.swiss-insurance-paragraph1")}</p>

      <ul>
        <li>
          <a href="/contact-us" target="_blank" rel="noopener noreferrer">
            <strong>
              {t("tasks.weekly-commuter.swiss-insurance-list1-item1-bold")}
            </strong>
          </a>{" "}
          {t("tasks.weekly-commuter.swiss-insurance-list1-item1")}
        </li>
        <li>
          <strong>
            {t("tasks.weekly-commuter.swiss-insurance-list1-item2-bold")}
          </strong>{" "}
          {t("tasks.weekly-commuter.swiss-insurance-list1-item2")}
        </li>
        <li>
          <strong>
            {t("tasks.weekly-commuter.swiss-insurance-list1-item3-bold")}
          </strong>{" "}
          {t("tasks.weekly-commuter.swiss-insurance-list1-item3")}
        </li>
      </ul>
    </div>
  );
};

// TASK RECOGNITION OF PROF

export const WeeklyRecognitionProfesionalQualificationsDescription: React.FC =
  () => {
    const [t, i18n] = useTranslation("global");
    return (
      <div>
        <p>
          {t("tasks.weekly-commuter.profesional-qualifications-paragraph1")}{" "}
          <a href="/videolibrary" target="_blank" rel="noopener noreferrer">
            <strong>
              {t(
                "tasks.weekly-commuter.profesional-qualifications-paragraph1-link"
              )}
            </strong>
          </a>{" "}
          {t("tasks.weekly-commuter.profesional-qualifications-paragraph2")}
        </p>

        <ul>
          <li>
            <a href="/videolibrary" target="_blank" rel="noopener noreferrer">
              <strong>
                {t(
                  "tasks.weekly-commuter.profesional-qualifications-list1-item1-bold"
                )}
              </strong>
            </a>{" "}
            {t("tasks.weekly-commuter.profesional-qualifications-list1-item1")}
          </li>
          <li>
            <strong>
              {" "}
              {t(
                "tasks.weekly-commuter.profesional-qualifications-list1-item2-bold"
              )}{" "}
            </strong>
            <a
              href="https://www.edk.ch/de/themen/diplomanerkennung"
              target="_blank"
              rel="noopener noreferrer"
            >
              Anerkennung.Swiss{" "}
            </a>
          </li>
        </ul>
      </div>
    );
  };

export const WeeklyEBankingTwintDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.weekly-commuter.ebanking-twint-paragraph1")}</p>
      <ul>
        <li>
          <strong>
            {" "}
            {t("tasks.weekly-commuter.ebanking-twint-list1-item1-bold")}
          </strong>{" "}
          {t("tasks.weekly-commuter.ebanking-twint-list1-item1")}
        </li>

        <li>
          <strong>
            {" "}
            {t("tasks.weekly-commuter.ebanking-twint-list1-item2-bold")}
          </strong>{" "}
          {t("tasks.weekly-commuter.ebanking-twint-list1-item2")}
        </li>
      </ul>
    </div>
  );
};

export const WeeklyMobilityDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.weekly-commuter.mobility-paragraph1")}</p>

      <ul>
        <li>
          <a href="/videolibrary" target="_blank" rel="noopener noreferrer">
            <strong>
              {" "}
              {t("tasks.weekly-commuter.mobility-list1-item1-bold")}{" "}
            </strong>
          </a>{" "}
          {t("tasks.weekly-commuter.mobility-list1-item1")}
        </li>
        <li>
          <a
            href="https://www.sbb.ch/de/billette-angebote/abos/halbtax.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong>
              {" "}
              {t("tasks.weekly-commuter.mobility-list1-item2-bold")}{" "}
            </strong>{" "}
          </a>{" "}
          {t("tasks.weekly-commuter.mobility-list1-item2")}
        </li>
        <li>
          <a
            href="https://www.swisspass.ch/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong>
              {" "}
              {t("tasks.weekly-commuter.mobility-list1-item3-bold")}{" "}
            </strong>
          </a>{" "}
          {t("tasks.weekly-commuter.mobility-list1-item3")}
        </li>
        <li>
          <strong>
            {" "}
            {t("tasks.weekly-commuter.mobility-list1-item4-bold")}{" "}
          </strong>{" "}
          {t("tasks.weekly-commuter.mobility-list1-item4")}
        </li>
        <li>
          <strong>
            {" "}
            {t("tasks.weekly-commuter.mobility-list1-item5-bold")}{" "}
          </strong>
          {t("tasks.weekly-commuter.mobility-list1-item5")}
        </li>
      </ul>
    </div>
  );
};

export const WeeklySupermarketsDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.weekly-commuter.supermarkets-paragraph1")}</p>

      <ul>
        <li>
          <strong>
            {" "}
            {t("tasks.weekly-commuter.supermarkets-list1-item1-bold")}{" "}
          </strong>
          {t("tasks.weekly-commuter.supermarkets-list1-item1")}
        </li>

        <li>
          <strong>
            {" "}
            {t("tasks.weekly-commuter.supermarkets-list1-item2-bold")}{" "}
          </strong>
          {t("tasks.weekly-commuter.supermarkets-list1-item2")}
        </li>

        <li>
          <strong>
            {" "}
            {t("tasks.weekly-commuter.supermarkets-list1-item3-bold")}{" "}
          </strong>
          {t("tasks.weekly-commuter.supermarkets-list1-item3")}
        </li>

        <li>
          <strong>
            {" "}
            {t("tasks.weekly-commuter.supermarkets-list1-item4-bold")}{" "}
          </strong>
          {t("tasks.weekly-commuter.supermarkets-list1-item4")}
        </li>
      </ul>
    </div>
  );
};

export const WeeklyOnlineShoppingDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.weekly-commuter.online-shopping-paragraph1")}</p>
      <ul>
        <li>
          <a
            href="https://www.galaxus.ch/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong>Galaxus</strong>
          </a>
          <strong> / </strong>
          <a
            href="https://www.digitec.ch/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong>Digitec:</strong>
          </a>{" "}
          {t("tasks.weekly-commuter.online-shopping-list1-item1")}
        </li>
        <li>
          <a
            href="https://www.tutti.ch/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong>Tutti</strong>
          </a>
          <strong> / </strong>
          <a
            href="https://www.ricardo.ch/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong>Ricardo:</strong>
          </a>{" "}
          {t("tasks.weekly-commuter.online-shopping-list1-item2")}
        </li>
      </ul>
    </div>
  );
};

export const WeeklySerafeDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.weekly-commuter.serafe-paragraph1")}</p>
    </div>
  );
};

export const WeeklyWasteDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>
        {t("tasks.weekly-commuter.waste-paragraph1")}

        <ul>
          <li>
            <strong>
              {" "}
              {t("tasks.weekly-commuter.waste-list1-item1-bold")}{" "}
            </strong>
            {t("tasks.weekly-commuter.waste-list1-item1")}
          </li>

          <li>
            <strong>
              {" "}
              {t("tasks.weekly-commuter.waste-list1-item2-bold")}{" "}
            </strong>
            {t("tasks.weekly-commuter.waste-list1-item2")}
          </li>

          <li>
            <strong>
              {" "}
              {t("tasks.weekly-commuter.waste-list1-item3-bold")}{" "}
            </strong>
            {t("tasks.weekly-commuter.waste-list1-item3")}
          </li>
        </ul>
      </p>
    </div>
  );
};

// TASK POSTSYSTEM
export const WeeklySwissPostalSystem: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.weekly-commuter.postal-system-paragraph1")} </p>

      <ul>
        <li>
          <strong>
            {t("tasks.weekly-commuter.postal-system-list1-item1-bold")}{" "}
          </strong>{" "}
          {t("tasks.weekly-commuter.postal-system-list1-item1")}
        </li>
        <li>
          <strong>
            {t("tasks.weekly-commuter.postal-system-list1-item2-bold")}
          </strong>{" "}
          {t("tasks.weekly-commuter.postal-system-list1-item2")}
        </li>
        <li>
          <strong>
            {t("tasks.weekly-commuter.postal-system-list1-item3-bold")}
          </strong>{" "}
          {t("tasks.weekly-commuter.postal-system-list1-item3")}
        </li>
      </ul>
    </div>
  );
};

// TASK CHILD ALLOWANCE ACCOUNT

export const WeeklyChildAllowanceDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>{t("tasks.weekly-commuter.child-allowance-paragraph1")}</p>
      <p>{t("tasks.weekly-commuter.child-allowance-paragraph2")}</p>
    </div>
  );
};


// TASK IMPORTANT DOCUMENTS READY
export const WeeklyRetirementDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");

  return (
    <div>
      <p>
        {t("tasks.weekly-commuter.retirement-paragraph1")}{" "}
        <a href="/contact-us" target="_blank" rel="noopener noreferrer">
          <strong>
            {t("tasks.weekly-commuter.retirement-paragraph1-link")}
          </strong>
        </a>
      </p>
    </div>
  );
};

// TASK TAX DECLARATION
export const WeeklyTaxDeclarationDescription: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <p>
        {t("tasks.weekly-commuter.tax-declaration-paragraph1")}{" "}
        <a href="/contact-us" target="_blank" rel="noopener noreferrer">
          <strong>{t("tasks.weekly-commuter.tax-declaration-paragraph1-link")}</strong>
        </a>
      </p>
      <ul>
        <li>
          <a href="/videolibrary" target="_blank" rel="noopener noreferrer">
            <strong>
              {t("tasks.weekly-commuter.tax-declaration-list1-item1-bold")}
            </strong>
          </a>{" "}
          {t("tasks.weekly-commuter.tax-declaration-list1-item1")}
        </li>
      </ul>
    </div>
  );
};
